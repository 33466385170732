<template>
  <v-expansion-panel style="margin-left: 30px">
    <v-expansion-panel-header>
      <div class="list-item">
        <a class="list-item-name">
          <button :class="depth == 0 ? 'toggleSwitch' : 'toggleSwitch whiteBg'">
            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
            </svg>
            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
            </svg>
          </button>
          <span> {{ prefix }} {{ groupConfig.title }} </span>
        </a>
        <v-progress-circular v-if="depth == 0"
                             :rotate="-90" :size="50" :value="getProgress()" :width="6" color="#0033A0" class="ml-auto"> {{ getProgress() }}
        </v-progress-circular>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-for="(group, group_idx) of groupConfig.groups" :key="group_idx+'_gro'">
        <v-expansion-panels
            v-if="groupConfig.groups?.length > 0"
            class="list-item-wrap">
          <check-list-group
              :group-config="group"
              :domain="domain"
              :period="period"
              :period-span="periodSpan"
              :depth="+depth + 1"
              :prefix="prefix+(group_idx+1)+'.'"
              :values="values"
              :controls="controls"
              :controller-roles="controllerRoles"
              :editor-roles="editorRoles"
              @updated="$emit('updated')"
          ></check-list-group>
        </v-expansion-panels>
      </div>
      <template v-if="groupConfig.questions">
        <table>
          <thead>
          <tr>
            <th>Параметр</th>
            <th>
              <div>
                <span>Данные за {{ getPeriodName(period) }}</span>
                <ClientCentricityChecklistEditButton
                    v-if="controllerRoles.includes($user.role) || editorRoles.includes($user.role)"
                    :config="groupConfig"
                    :domain="domain"
                    :period="period"
                    :period-span="periodSpan"
                    :prefix="prefix"
                    :controller-roles="controllerRoles"
                    :editor-roles="editorRoles"
                    @updated="$emit('updated')"/>
              </div>
            </th>
            <th>Контроль</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(question, question_idx) of groupConfig.questions" :key="question_idx+'_que'">
            <td style="width: 300px;">
              {{ prefix }}{{ (groupConfig.groups?.length || 0) + question_idx + 1 }}
              {{ question.title }}
            </td>
            <template v-if="question.type === 'DYNAMIC'">
              <td>
                <div>
                  <svg width="18" height="14" viewBox="0 0 18 14" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.8906 1.45847e-06H2.10938C1.54993 1.45847e-06 1.01341 0.172853 0.617822 0.480529C0.222237 0.788206 0 1.20551 0 1.64063L0 8.70297C0 9.13809 0.222237 9.55539 0.617822 9.86307C1.01341 10.1707 1.54993 10.3436 2.10938 10.3436H5.38312C5.42042 10.3436 5.45619 10.3551 5.48256 10.3756C5.50893 10.3961 5.52375 10.424 5.52375 10.453V13.2344C5.52374 13.3858 5.58146 13.5338 5.68962 13.6598C5.79779 13.7857 5.95153 13.8838 6.13141 13.9418C6.31129 13.9997 6.50923 14.0149 6.70019 13.9853C6.89115 13.9558 7.06655 13.8829 7.20422 13.7758L11.5748 10.3764C11.588 10.3663 11.6036 10.3583 11.6207 10.3528C11.6378 10.3474 11.6562 10.3446 11.6747 10.3447H15.8934C16.4529 10.3447 16.9894 10.1718 17.385 9.86416C17.7806 9.55648 18.0028 9.13919 18.0028 8.70406V1.64063C18.0028 1.42499 17.9482 1.21147 17.842 1.01228C17.7358 0.813088 17.5802 0.63213 17.384 0.479755C17.1878 0.327381 16.955 0.206579 16.6987 0.124258C16.4425 0.0419378 16.1679 -0.000286054 15.8906 1.45847e-06V1.45847e-06ZM17.1562 8.70297C17.1562 8.96404 17.0229 9.21442 16.7856 9.39903C16.5482 9.58364 16.2263 9.68735 15.8906 9.68735H11.6719C11.4118 9.68588 11.1615 9.76452 10.9758 9.9061L6.60938 13.3109C6.58971 13.3264 6.56458 13.3369 6.53719 13.3412C6.5098 13.3454 6.4814 13.3433 6.4556 13.3349C6.42981 13.3266 6.40778 13.3125 6.39234 13.2944C6.3769 13.2763 6.36874 13.255 6.36891 13.2333V10.453C6.36891 10.2499 6.2652 10.0552 6.08059 9.91159C5.89598 9.76801 5.6456 9.68735 5.38453 9.68735H2.10938C1.77371 9.68735 1.45179 9.58364 1.21444 9.39903C0.977092 9.21442 0.84375 8.96404 0.84375 8.70297V1.64063C0.84375 1.37955 0.977092 1.12917 1.21444 0.944568C1.45179 0.759962 1.77371 0.656252 2.10938 0.656252H15.8906C16.2263 0.656252 16.5482 0.759962 16.7856 0.944568C17.0229 1.12917 17.1562 1.37955 17.1562 1.64063V8.70297Z"
                        fill="#777779"/>
                  </svg>
                  {{
                    (t = getValue(question.id)) ? t : "—"
                  }}
                </div>
                <div>
                  <svg width="18" height="14" viewBox="0 0 18 14" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.8906 1.45847e-06H2.10938C1.54993 1.45847e-06 1.01341 0.172853 0.617822 0.480529C0.222237 0.788206 0 1.20551 0 1.64063L0 8.70297C0 9.13809 0.222237 9.55539 0.617822 9.86307C1.01341 10.1707 1.54993 10.3436 2.10938 10.3436H5.38312C5.42042 10.3436 5.45619 10.3551 5.48256 10.3756C5.50893 10.3961 5.52375 10.424 5.52375 10.453V13.2344C5.52374 13.3858 5.58146 13.5338 5.68962 13.6598C5.79779 13.7857 5.95153 13.8838 6.13141 13.9418C6.31129 13.9997 6.50923 14.0149 6.70019 13.9853C6.89115 13.9558 7.06655 13.8829 7.20422 13.7758L11.5748 10.3764C11.588 10.3663 11.6036 10.3583 11.6207 10.3528C11.6378 10.3474 11.6562 10.3446 11.6747 10.3447H15.8934C16.4529 10.3447 16.9894 10.1718 17.385 9.86416C17.7806 9.55648 18.0028 9.13919 18.0028 8.70406V1.64063C18.0028 1.42499 17.9482 1.21147 17.842 1.01228C17.7358 0.813088 17.5802 0.63213 17.384 0.479755C17.1878 0.327381 16.955 0.206579 16.6987 0.124258C16.4425 0.0419378 16.1679 -0.000286054 15.8906 1.45847e-06V1.45847e-06ZM17.1562 8.70297C17.1562 8.96404 17.0229 9.21442 16.7856 9.39903C16.5482 9.58364 16.2263 9.68735 15.8906 9.68735H11.6719C11.4118 9.68588 11.1615 9.76452 10.9758 9.9061L6.60938 13.3109C6.58971 13.3264 6.56458 13.3369 6.53719 13.3412C6.5098 13.3454 6.4814 13.3433 6.4556 13.3349C6.42981 13.3266 6.40778 13.3125 6.39234 13.2944C6.3769 13.2763 6.36874 13.255 6.36891 13.2333V10.453C6.36891 10.2499 6.2652 10.0552 6.08059 9.91159C5.89598 9.76801 5.6456 9.68735 5.38453 9.68735H2.10938C1.77371 9.68735 1.45179 9.58364 1.21444 9.39903C0.977092 9.21442 0.84375 8.96404 0.84375 8.70297V1.64063C0.84375 1.37955 0.977092 1.12917 1.21444 0.944568C1.45179 0.759962 1.77371 0.656252 2.10938 0.656252H15.8906C16.2263 0.656252 16.5482 0.759962 16.7856 0.944568C17.0229 1.12917 17.1562 1.37955 17.1562 1.64063V8.70297Z"
                        fill="#777779"/>
                  </svg>
                  {{
                    (t = getValue(question.id, previousPeriod(period, periodSpan))) ? t : "—"
                  }}
                </div>
                <div>
                  Динамика показателя:
                  {{ countParameterDynamic(question.id) }}
                </div>
              </td>
              <td>
                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                     :fill="getControl(question.id)?.flag">
                  <circle cx="7" cy="7" r="7"/>
                </svg>
                {{
                  (t = getControl(question.id)?.comment) ? t : "—"
                }}
                <br>балл: {{
                  hasValue((t = getControl(question.id)?.score)) ? t : "—"
                }}
              </td>
            </template>
            <template v-else>
              <td>
                <template
                    v-if="(files=getAttachments(question.id)) && files.length>0">
                  <div v-for="file in files" :key="file.id + 'fileIndex'">
                    <svg width="10" height="20" viewBox="0 0 10 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M8.94029 5.78581C8.59471 5.78581 8.31597 6.05336 8.31597 6.39919V15.4626C8.31597 16.388 7.98158 17.146 7.33488 17.7927C6.68819 18.4394 5.91905 18.7738 4.98271 18.7738C4.06847 18.7738 3.28814 18.4505 2.64147 17.8039C1.99481 17.1572 1.67158 16.3769 1.67158 15.4626V12.0402V10.2119V5.7078V3.54518V3.5228V3.50045C1.67158 2.88732 1.89728 2.35505 2.32943 1.91742C2.76705 1.47434 3.29908 1.23745 3.93481 1.23745C4.57031 1.23745 5.10259 1.47434 5.53993 1.91742C5.97208 2.35505 6.19781 2.88732 6.19781 3.50045V3.5228V3.5673V13.6791C6.19781 14.3143 5.70738 14.805 5.0719 14.805C4.45875 14.805 3.95693 14.2922 3.95693 13.6791V8.38343C3.95693 8.03785 3.68935 7.7703 3.3438 7.7703C2.99824 7.7703 2.73067 8.03785 2.73067 8.38343V13.6791C2.73067 14.3367 2.95375 14.8942 3.41064 15.3513C3.86776 15.8084 4.41408 16.0313 5.07193 16.0313C5.72953 16.0313 6.28152 15.8027 6.74413 15.3513C7.19579 14.9106 7.42413 14.3591 7.42413 13.6791V3.5228V3.50045C7.42413 2.54174 7.07569 1.71955 6.39857 1.03672C5.71572 0.348182 4.89352 0 3.93481 0C2.97587 0 2.15367 0.348182 1.47084 1.03672C0.793747 1.71955 0.445312 2.54174 0.445312 3.50045V3.5228V5.7078V10.2119V12.0402V15.4626C0.445312 16.7224 0.891247 17.7927 1.77195 18.6733C2.65266 19.554 3.72289 20 4.98271 20C6.24254 20 7.33488 19.5764 8.22678 18.6845C9.11865 17.7927 9.55342 16.7003 9.55342 15.4626V6.39919C9.55342 6.05336 9.28584 5.78581 8.94029 5.78581Z"
                          fill="#777779"/>
                    </svg>
                    <a @click="downloadAttachment(file.id, file.name)">
                      {{ truncateStr(file.name, 30) }}
                    </a>
                  </div>
                </template>
                <template v-else>
                  <svg width="10" height="20" viewBox="0 0 10 20" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.94029 5.78581C8.59471 5.78581 8.31597 6.05336 8.31597 6.39919V15.4626C8.31597 16.388 7.98158 17.146 7.33488 17.7927C6.68819 18.4394 5.91905 18.7738 4.98271 18.7738C4.06847 18.7738 3.28814 18.4505 2.64147 17.8039C1.99481 17.1572 1.67158 16.3769 1.67158 15.4626V12.0402V10.2119V5.7078V3.54518V3.5228V3.50045C1.67158 2.88732 1.89728 2.35505 2.32943 1.91742C2.76705 1.47434 3.29908 1.23745 3.93481 1.23745C4.57031 1.23745 5.10259 1.47434 5.53993 1.91742C5.97208 2.35505 6.19781 2.88732 6.19781 3.50045V3.5228V3.5673V13.6791C6.19781 14.3143 5.70738 14.805 5.0719 14.805C4.45875 14.805 3.95693 14.2922 3.95693 13.6791V8.38343C3.95693 8.03785 3.68935 7.7703 3.3438 7.7703C2.99824 7.7703 2.73067 8.03785 2.73067 8.38343V13.6791C2.73067 14.3367 2.95375 14.8942 3.41064 15.3513C3.86776 15.8084 4.41408 16.0313 5.07193 16.0313C5.72953 16.0313 6.28152 15.8027 6.74413 15.3513C7.19579 14.9106 7.42413 14.3591 7.42413 13.6791V3.5228V3.50045C7.42413 2.54174 7.07569 1.71955 6.39857 1.03672C5.71572 0.348182 4.89352 0 3.93481 0C2.97587 0 2.15367 0.348182 1.47084 1.03672C0.793747 1.71955 0.445312 2.54174 0.445312 3.50045V3.5228V5.7078V10.2119V12.0402V15.4626C0.445312 16.7224 0.891247 17.7927 1.77195 18.6733C2.65266 19.554 3.72289 20 4.98271 20C6.24254 20 7.33488 19.5764 8.22678 18.6845C9.11865 17.7927 9.55342 16.7003 9.55342 15.4626V6.39919C9.55342 6.05336 9.28584 5.78581 8.94029 5.78581Z"
                        fill="#777779"/>
                  </svg>
                  <span>—</span>
                </template>
                <div>
                  <svg width="18" height="14" viewBox="0 0 18 14" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.8906 1.45847e-06H2.10938C1.54993 1.45847e-06 1.01341 0.172853 0.617822 0.480529C0.222237 0.788206 0 1.20551 0 1.64063L0 8.70297C0 9.13809 0.222237 9.55539 0.617822 9.86307C1.01341 10.1707 1.54993 10.3436 2.10938 10.3436H5.38312C5.42042 10.3436 5.45619 10.3551 5.48256 10.3756C5.50893 10.3961 5.52375 10.424 5.52375 10.453V13.2344C5.52374 13.3858 5.58146 13.5338 5.68962 13.6598C5.79779 13.7857 5.95153 13.8838 6.13141 13.9418C6.31129 13.9997 6.50923 14.0149 6.70019 13.9853C6.89115 13.9558 7.06655 13.8829 7.20422 13.7758L11.5748 10.3764C11.588 10.3663 11.6036 10.3583 11.6207 10.3528C11.6378 10.3474 11.6562 10.3446 11.6747 10.3447H15.8934C16.4529 10.3447 16.9894 10.1718 17.385 9.86416C17.7806 9.55648 18.0028 9.13919 18.0028 8.70406V1.64063C18.0028 1.42499 17.9482 1.21147 17.842 1.01228C17.7358 0.813088 17.5802 0.63213 17.384 0.479755C17.1878 0.327381 16.955 0.206579 16.6987 0.124258C16.4425 0.0419378 16.1679 -0.000286054 15.8906 1.45847e-06V1.45847e-06ZM17.1562 8.70297C17.1562 8.96404 17.0229 9.21442 16.7856 9.39903C16.5482 9.58364 16.2263 9.68735 15.8906 9.68735H11.6719C11.4118 9.68588 11.1615 9.76452 10.9758 9.9061L6.60938 13.3109C6.58971 13.3264 6.56458 13.3369 6.53719 13.3412C6.5098 13.3454 6.4814 13.3433 6.4556 13.3349C6.42981 13.3266 6.40778 13.3125 6.39234 13.2944C6.3769 13.2763 6.36874 13.255 6.36891 13.2333V10.453C6.36891 10.2499 6.2652 10.0552 6.08059 9.91159C5.89598 9.76801 5.6456 9.68735 5.38453 9.68735H2.10938C1.77371 9.68735 1.45179 9.58364 1.21444 9.39903C0.977092 9.21442 0.84375 8.96404 0.84375 8.70297V1.64063C0.84375 1.37955 0.977092 1.12917 1.21444 0.944568C1.45179 0.759962 1.77371 0.656252 2.10938 0.656252H15.8906C16.2263 0.656252 16.5482 0.759962 16.7856 0.944568C17.0229 1.12917 17.1562 1.37955 17.1562 1.64063V8.70297Z"
                        fill="#777779"/>
                  </svg>
                  {{
                    (t = truncateStr(getValue(question.id), 50)) ? t : "—"
                  }}
                </div>
              </td>
              <td>
                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                     :fill="getControl(question.id)?.flag">
                  <circle cx="7" cy="7" r="7"/>
                </svg>
                {{
                  (t = getControl(question.id)?.comment) ? t : "—"
                }}
                <br>балл: {{
                  hasValue((t = getControl(question.id)?.score)) ? t : "—"
                }}
              </td>
            </template>
          </tr>
          </tbody>
        </table>
      </template>
    </v-expansion-panel-content>
    <LoaderDialog :show="showLoaderDialog" msg="Загрузка файлов"></LoaderDialog>
  </v-expansion-panel>
</template>


<script>
import ClientCentricityChecklistEditButton from "@/components/icc_new/ClientCentricityChecklistEditButton";
import {truncateStr} from "@/modules/CommonUtils";
import {getPeriodName, previousPeriod} from "@/modules/periods";
import {downloadFile} from "@/modules/api.files";
import LoaderDialog from "@/components/elements/LoaderDialog";

export default {
  name: "CheckListGroup",
  components: {ClientCentricityChecklistEditButton, LoaderDialog},
  props: ['groupConfig', 'domain', 'period', 'periodSpan', 'depth', 'prefix', 'values', 'controls', 'controllerRoles', 'editorRoles'],
  data: () => ({
    showLoaderDialog: false
  }),

  computed: {},

  methods: {
    truncateStr: truncateStr,
    getPeriodName: getPeriodName,
    previousPeriod: previousPeriod,
    getProgress(){
      let [configValues, presentValues] = this.getValuesCount(this.groupConfig)
      return Math.round(presentValues / configValues * 100);
    },

    questionAnswered(question){
      if(question.type === 'DYNAMIC'){
        const value = this.getValue(question.id)
        const previousValue = this.getValue(question.id, previousPeriod(this.period, this.periodSpan))
        return value && previousValue
      } else {
        const attachments = this.getAttachments(question.id)
        return attachments && attachments.length > 0
      }


    },

    getValuesCount(config){
      let configValues = 0, presentValues = 0
      if(config.questions){
        for(let question of config.questions){
          configValues++
          if(this.questionAnswered(question)){
            presentValues++
          }
        }
      }
      if(config.groups){
        for(let group of config.groups){
          let [nestedConfigValues, nestedPresentValues] = this.getValuesCount(group)
          configValues += nestedConfigValues
          presentValues += nestedPresentValues
        }
      }
      return [configValues, presentValues]
    },

    countParameterDynamic(questionId){
      let currentValue = this.getValue(questionId)
      let previousValue = this.getValue(questionId, previousPeriod(this.period, this.periodSpan))

      if (currentValue != null && currentValue !== "" && previousValue != null && previousValue !== "") {
        let result = (currentValue - previousValue) / previousValue * 100;

        if (Number.isInteger(result)) {
          return result + "%";
        } else {
          return result.toFixed(2) + "%";
        }

      } else {
        return '—';
      }
    },

    async downloadAttachment(fileId, fileName) {
      this.showLoaderDialog = true
      await downloadFile(fileId, fileName)
      this.showLoaderDialog = false
    },

    getAttachments(questionId) {
      let filesValue = this.values.find(e => e.indicatorId === questionId && e.period === this.period && e.type === 'FILES')
      if (!filesValue) {
        return []
      }
      return JSON.parse(filesValue.value)
    },

    getValue(questionId, period) {
      if (period == null) {
        period = this.period
      }
      return this.values.find(e => e.indicatorId === questionId && e.period === period && e.type === 'VALUE')?.value
    },

    getControl(questionId){
      return this.controls.find(e => e.indicatorId === questionId && e.period === this.period)
    },

    hasValue(v){
      return v !== null && v !== undefined && v !== "";
    },

  },

  filters: {},
  async beforeMount() {
  },
}
</script>


<style scoped lang="scss">
.v-progress-circular {
  margin-right: 20px
}

h1 {
  display: flex;
  align-items: center;

  .badge {
    width: 152px;
    height: 60px;
    background: #E8E8E9;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
    color: $cblack;
  }

  .v-btn {
    margin-left: auto;
  }
}

.manual-link {
  margin: 20px 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      color: #0033a0;
      margin-right: 10px;
    }
  }
}

.list {
  table {
    margin-bottom: 25px;

    th {
      border-bottom: 1px solid #E8E8E9;

      div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    td:nth-child(2),
    th:nth-child(2) {
      text-align: right;
    }

    td > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      svg {
        margin-right: 8px;
        flex-shrink: 0;
        margin-top: 5px;
      }
    }

    tr:last-child td {
      border-bottom: 1px solid #E8E8E9;
    }
  }
}

.push-right {
  float: right;
  margin-top: 15px;
}
</style>

<style lang="scss">
@import "../../styles/main";
</style>

<style>
.v-progress-circular__info {
  font-size: 13px !important;
  text-align: center;
  width: 100%;
}
</style>
